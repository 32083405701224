import {
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { MdDirectionsBoat, MdLocationPin } from "react-icons/md";
import { GiFishingHook } from "react-icons/gi";
import { DailyTrip } from "../../models/dailyTrip";
import Card from "react-bootstrap/Card";
import { Grid } from "../../models/grid";
import Table from "react-bootstrap/Table";
import { Catch } from "../../models/catch";
import Button from "react-bootstrap/esm/Button";
import { FaPlusCircle } from "react-icons/fa";
import { Buyer, Sale } from "../../models/buyer";
import { useFormik } from "formik";
import * as Yup from "yup";
import Alert from "react-bootstrap/Alert";
import EffortAttributesList from "../EffortAttributesList";
import HarvestSale from "../formControls/harvestSale";
interface CombinedObject {
  speciesName?: string;
  totalLandedWeight?: number;
}

export default function ReviewDailyTrip({
  initialValues,
  refId,
  formData,
  gotoStep,
  isReadonly,
  recentBuyersList,
}: any) {
  // Submit form from parent component
  useImperativeHandle(refId, () => ({
    Submit: async () => {
      // Empty Errors Array
      const formErrors: SetStateAction<string[]> = [];

      //Submit all forms
      for (const key in buyerRefs.current) {
        if (buyerRefs.current[key] !== null)
          await buyerRefs.current[key].Submit();
      }

      const buyersWeightExceedsCatchErrors = checkSpeciesBuyersWeight();
      formErrors.push(...buyersWeightExceedsCatchErrors);

      // Check if all buyers have a name
      selectedBuyers.forEach((b) => {
        if (!b.validated) formErrors.push(`Buyer name missing`);
      });

      //Check if pounds sold or price per pound is negative
      for (let buyer of selectedBuyers) {
        for (let sale of buyer.sales!) {
          if (!formErrors.some((x) => x === "Pounds Sold cannot be negative")) {
            if (sale.weight !== null && sale.weight! < 0) {
              formErrors.push("Pounds Sold cannot be negative");
            }
          }

          if (
            !formErrors.some((x) => x === "Price Per Pound cannot be negative")
          ) {
            if (sale.pricePerPound !== null && sale.pricePerPound! < 0) {
              formErrors.push("Price Per Pound cannot be negative");
            }
          }
        }
      }

      // const isWeightValid = checkSpeciesWeight(values.harvest, values.buyers);

      // if (!isWeightValid) {
      //   formErrors.push("Pounds sold must be less than harvested");
      // }

      // Check if total lbs sold per species is not greater than what was caught

      if (formErrors.length === 0) {
        formData({
          values: { ...values, buyers: selectedBuyers },
          validated: true,
        });
      }
      setErrors(formErrors);
    },
  }));

  // Buyer Start
  // Set init values against the interface
  const [values, setValues] = useState<DailyTrip>({ ...initialValues });
  const [errors, setErrors] = useState<string[]>([]);
  const buyerRefs = useRef<any>({});
  const [selectedBuyers, setSelectedBuyers] = useState<Buyer[]>(values.buyers);
  const [buyerID, setBuyerID] = useState(0);

  const combinedSpeciesWeight = values.harvest.reduce(
    (result: CombinedObject[], obj: any) => {
      const existingObj = result.find(
        (item) => item.speciesName === obj.speciesName
      );
      if (existingObj?.totalLandedWeight && obj?.landedWeight) {
        existingObj.totalLandedWeight += obj.landedWeight;
      } else {
        result.push({
          speciesName: obj.speciesName,
          totalLandedWeight: obj.landedWeight,
        });
      }
      return result;
    },
    []
  );

  const checkSpeciesBuyersWeight = () => {
    const combinedBuyers: CombinedObject[] = [];
    const errors: string[] = [];

    selectedBuyers.forEach((buyer) => {
      if (buyer.sales) {
        buyer.sales.forEach((sale) => {
          const existingObj = combinedBuyers.find(
            (item) => item.speciesName === sale.speciesName
          );
          if (existingObj) {
            if (sale.weight && existingObj.totalLandedWeight) {
              existingObj.totalLandedWeight += sale.weight;
            }
          } else {
            if (sale.weight) {
              combinedBuyers.push({
                speciesName: sale.speciesName,
                totalLandedWeight: sale.weight,
              });
            } else {
              combinedBuyers.push({
                speciesName: sale.speciesName,
                totalLandedWeight: 0,
              });
            }
          }
        });
      }
    });

    combinedBuyers.forEach((sale) => {
      const species = combinedSpeciesWeight.find(
        (item) => item.speciesName === sale.speciesName
      );
      if (
        species &&
        sale.totalLandedWeight &&
        species.totalLandedWeight &&
        sale.totalLandedWeight > species.totalLandedWeight
      ) {
        errors.push(
          `${sale.speciesName} sold (${sale.totalLandedWeight} lbs.) exceeds total weight landed (${species.totalLandedWeight} lbs.)`
        );
      }
    });

    return errors;
  };

  // Save data on change
  useEffect(() => {
    formData({
      values: { ...values, buyers: selectedBuyers },
      validated: false,
    });
  }, [selectedBuyers]);

  const addBuyer = () => {
    const newID = Math.min(...selectedBuyers.map((b) => b.id), buyerID) - 1;

    const newBuyer: Buyer = {
      id: newID,
      buyerName: "",
      sales: [],
    };

    setBuyerID(newID);
    setSelectedBuyers([...selectedBuyers, newBuyer]);
  };

  const removeBuyer = (id: number) => {
    setSelectedBuyers(selectedBuyers.filter((b) => b.id !== id));
  };

  const updateBuyer = (buyerID: number, buyerData: Buyer) => {
    const index = selectedBuyers.findIndex((x) => x.id === buyerID);

    selectedBuyers[index] = { ...selectedBuyers[index], ...buyerData };
    setSelectedBuyers(selectedBuyers);
  };
  // Buyer End

  const getRoundedNum = (num: number) => {
    return Math.round(num * 100) / 100;
  };

  return (
    <>
      {!isReadonly && <h2 className="mb-5 fw-normal">Review Daily Trip</h2>}

      {/* Trip Details */}
      <div className="mt-5 form-card">
        <Row>
          <Col
            xs={4}
            className="mb-3 d-flex flex-column text-center text align-items-center"
          >
            <GiFishingHook className="fs-1 text-primary" />
            <div>
              <strong>Gear</strong>
              <p className="mb-0">
                {values.effort.length ? values.effort[0].gearName : "No Gear"}
              </p>
            </div>
          </Col>

          <Col
            xs={4}
            className="mb-3 d-flex flex-column text-center text align-items-center"
          >
            <MdDirectionsBoat className="fs-1 text-primary" />
            <div>
              <strong>Vessel</strong>
              <p className="mb-0">
                {values.vessel?.name !== undefined ? (
                  <>{values.vessel.name}</>
                ) : values.vesselName ? (
                  <>{values.vesselName}</>
                ) : (
                  <>No Vessel</>
                )}
              </p>
            </div>
          </Col>

          <Col
            xs={4}
            className="mb-3 d-flex flex-column text-center text align-items-center"
          >
            <MdLocationPin className="fs-1 text-primary" />
            <div>
              <strong>Launch Site</strong>
              <p className="mb-0">
                {values.launchSite && values.launchSite.trim() !== ""
                  ? values.launchSite
                  : "No Launch Site"}
              </p>
            </div>
          </Col>
        </Row>
      </div>

      {/* Effort and Harvest */}
      <p className="fw-bold">Effort & Harvest</p>
      {values.grids !== undefined &&
        values.grids?.length > 0 &&
        values.grids?.map((grid: Grid, i: number) => {
          let effort = values.effort.filter((e) =>
            e.grids.some((g) => g.gridNumber === grid.gridNumber)
          );

          return (
            <div className="form-card mb-3">
              <p className="lead">
                Lake {grid.lakeName} - Grid {grid.gridNumber}
              </p>
              {/* <Table>
                  <thead>
                    <tr>
                      <th>Effort & Details</th>
                      {!isReadonly && (
                        <th className="text-end">
                          <span
                            className="link fw-normal"
                            onClick={() => gotoStep("effort")}
                          >
                            Edit
                          </span>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody></tbody>
                </Table> */}
              <ul className="list-unstyled">
                {effort.length > 0 &&
                  effort.map((e, i) => {
                    return (
                      <li key={i}>
                        <EffortAttributesList
                          effort={{ ...values.gear[0], ...e }}
                        />
                      </li>
                    );
                  })}
              </ul>

              <Table responsive striped className="text-nowrap">
                <thead>
                  <tr>
                    <th>Harvest</th>
                    <th>Weight Landed (lbs)</th>
                    <th>Estimated Discard (lbs)</th>
                    <th>Condition</th>
                    <th className="text-end">
                      {!isReadonly && (
                        <span
                          className="link fw-normal"
                          onClick={() => gotoStep("fishCaught")}
                        >
                          Edit
                        </span>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {values.harvest.map((harvest, i) => {
                    return (
                      harvest.grids?.some(
                        (g) => g.gridNumber === grid.gridNumber
                      ) && (
                        <tr key={i}>
                          <td>{harvest.speciesName}</td>
                          <td>
                            {harvest.landedWeight
                              ? getRoundedNum(
                                  harvest.landedWeight / harvest.grids.length
                                )
                              : 0}
                          </td>
                          <td>
                            {harvest.discardWeight
                              ? getRoundedNum(
                                  harvest.discardWeight / harvest.grids.length
                                )
                              : 0}
                          </td>
                          <td colSpan={2}>{harvest.condition}</td>
                        </tr>
                      )
                    );
                  })}
                </tbody>
              </Table>
            </div>
          );
        })}

      {/* Buyers */}
      {(!isReadonly || selectedBuyers.length > 0) && (
        <div className="my-5">
          <p className="fw-bold">Buyers & Fish Sales</p>
          {selectedBuyers.length > 0 ? (
            selectedBuyers.map((b) => (
              <BuyerSales
                key={b.id}
                buyer={b}
                harvest={values.harvest}
                updateBuyer={updateBuyer}
                removeBuyer={removeBuyer}
                refID={(ref: any) => (buyerRefs.current[b.id] = ref)}
                isReadonly={isReadonly}
                recentBuyersList={recentBuyersList}
              />
            ))
          ) : (
            <p className="text-gray text-center lead mt-3">
              No Buyers or Fish Sales
            </p>
          )}

          {!isReadonly && (
            <div className="text-center">
              <Button
                onClick={() => addBuyer()}
                className="px-4"
                variant="outline-primary"
              >
                <FaPlusCircle className="me-2 mt-n1" />
                Add a Buyer
              </Button>
            </div>
          )}
        </div>
      )}

      {/* Comments and Notes */}
      {(!isReadonly || values.comments) && (
        <div className="my-5">
          <p className="fw-bold">Comments & Notes</p>
          <Form.Group className="mb-3" controlId="dt-comments-notes">
            <Form.Control
              as="textarea"
              rows={3}
              defaultValue={values.comments}
              onBlur={(e) => {
                values.comments = e.target.value;
                formData({
                  values: { ...values, buyers: selectedBuyers },
                  validated: false,
                });
              }}
              disabled={isReadonly}
            />
          </Form.Group>
        </div>
      )}

      {errors.length > 0 && (
        <Alert variant="danger" className="mt-4 mb-0">
          <Alert.Heading>Error</Alert.Heading>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </Alert>
      )}
    </>
  );
}

const BuyerSales = ({
  buyer,
  harvest,
  updateBuyer,
  removeBuyer,
  refID,
  isReadonly,
  recentBuyersList = [],
}: any) => {
  // Submit form from parent component

  useImperativeHandle(refID, () => ({
    Submit: async () => {
      await formik.submitForm();
      const errors = await formik.validateForm();
      // const buyerSales = removeEmptySpecies();

      const data: Buyer = {
        id: buyer.id,
        validated: Object.keys(errors).length === 0,
        ...formik.values,
      };

      updateBuyer(buyer.id, data);
    },
  }));

  useEffect(() => {
    // Add harvest as catch to buyer
    harvest.forEach((h: Catch) => {
      if (!buyer.sales.some((s: Sale) => s.speciesID === h.speciesID)) {
        buyer.sales.push({
          speciesID: h.speciesID,
          speciesName: h.speciesName,
          weight: undefined,
          pricePerPound: undefined,
        });
      }
    });

    // Remove sale from buyer if it's not in harvest
    for (let i = buyer.sales.length - 1; i >= 0; i--) {
      if (
        !harvest.some((h: Catch) => h.speciesID === buyer.sales[i].speciesID)
      ) {
        buyer.sales.splice(i, 1);
      }
    }
  }, []);

  // Init Formik
  const formik = useFormik({
    initialValues: {
      buyerName: buyer.buyerName ?? "",
      docksideSale: buyer.docksideSale ?? undefined,
      sales: buyer.sales ?? [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      buyerName: Yup.string().required().min(1),
    }),
    onSubmit: () => {},
  });

  useEffect(() => {
    updateBuyer(buyer.id, formik.values);
  }, [formik.values]);

  const updateSale = (index: number, data: any) => {
    formik.values.sales[index].weight = data.weight;
    formik.values.sales[index].pricePerPound = data.pricePerPound;
  };

  return (
    <div className="form-card mb-3">
      <Form
        onSubmit={formik.handleSubmit}
        // ref={refId}
        noValidate
        autoComplete="off"
      >
        <Row>
          <Form.Group
            as={Col}
            xs={12}
            md={6}
            className="mb-3"
            controlId="buyerName"
          >
            <Form.Label>
              Buyer name
              {!isReadonly && (
                <span className="text-danger text-small ms-2">(Required)</span>
              )}
            </Form.Label>
            <Form.Control
              list="recentBuyersList"
              placeholder="Jane Doe, Farmers Market, etc..."
              type="text"
              name="buyerName"
              value={formik.values.buyerName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.buyerName && !!formik.errors.buyerName}
              disabled={isReadonly}
            />
            {recentBuyersList !== null && recentBuyersList.length > 0 && (
              <datalist id="recentBuyersList">
                {recentBuyersList.map((b: Buyer, i: number) => (
                  <option key={i} value={b.buyerName} />
                ))}
              </datalist>
            )}
          </Form.Group>
          <Form.Group
            as={Col}
            xs={12}
            md={6}
            className="mb-3 mt-md-4 pt-md-3"
            id="docksideSale"
          >
            <Form.Check
              type="checkbox"
              label="Dockside Sale"
              name="docksideSale"
              value={formik.values.docksideSale}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.docksideSale}
              disabled={isReadonly}
            />
          </Form.Group>
        </Row>

        {/* Add Fish Sales */}
        {buyer.sales.length > 0 ? (
          <>
            <Row className="d-none d-md-flex">
              <Col md={3}>Species</Col>
              <Col md={3}>Pounds sold</Col>
              <Col md={3}>Price per pound</Col>
              <Col md={3}>Total Sale</Col>
            </Row>
            <hr className="mt-2 border-top bg-dark border-dark" />
            {buyer.sales.map((sale: Sale, index: number) => (
              <HarvestSale
                key={sale.id}
                sale={sale}
                index={index}
                updateSale={updateSale}
                isReadonly={isReadonly}
              />
            ))}
          </>
        ) : (
          <h2 className="text-light-gray text-center">No fish caught</h2>
        )}
      </Form>
      {!isReadonly && (
        <div className="text-end">
          <span
            onClick={() => removeBuyer(buyer.id)}
            className="cursor-pointer text-danger"
          >
            Remove
          </span>
        </div>
      )}
    </div>
  );
};
