import { useEffect, useImperativeHandle, useRef } from "react";
import { useFormik } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Condition } from "../../models/condition";
import { Catch } from "../../models/catch";
import { Grid } from "../../models/grid";
import { getLakeFromGrid } from "../../helpers/getLakeFromGrid";

export default function HarvestEffort({
  index,
  harvest,
  grids,
  isSplitEvenly,
  conditions,
  removeHarvest,
  updateHarvest,
  useRadioControl = false,
  refID,
  newlyAdded,
}: any) {
  // Submit form from parent component
  useImperativeHandle(refID, () => ({
    Submit: async () => {
      await formik.submitForm();

      const errors =
        parseInt(
          formik.values.landedWeight !== undefined &&
            formik.values.landedWeight !== ""
            ? formik.values.landedWeight
            : 0
        ) +
          parseInt(
            formik.values.discardWeight !== undefined &&
              formik.values.discardWeight !== ""
              ? formik.values.discardWeight
              : 0
          ) >
        0;

      const data: Catch = {
        validated: errors,
        ...formik.values,
        landedWeight: parseInt(
          formik.values.landedWeight !== undefined &&
            formik.values.landedWeight !== ""
            ? formik.values.landedWeight
            : 0
        ),
        discardWeight: parseInt(
          formik.values.discardWeight !== undefined &&
            formik.values.discardWeight !== ""
            ? formik.values.discardWeight
            : 0
        ),
        conditionID: parseInt(formik.values.conditionID),
        condition: conditions.find(
          (x: any) => x.id == formik.values.conditionID
        ).name,
      };

      updateHarvest(harvest.id, data);
    },
  }));

  const inputRef = useRef<any>(null);
  const initialGridKeys = () => {
    const gridKeys: any = [];

    harvest.grids.forEach((g: Grid) => {
      gridKeys.push(g.gridKey);
    });

    return gridKeys;
  };

  useEffect(() => {
    if (newlyAdded) {
      inputRef.current.focus();
    }
  }, []);

  // Init Formik
  const formik = useFormik({
    initialValues: {
      id: harvest.id,
      gridKey: initialGridKeys(),
      speciesID: harvest.speciesID,
      hasDiscard: harvest.hasDiscard ?? false,
      discardWeight: harvest.discardWeight ?? undefined,
      landedWeight: harvest.landedWeight ?? undefined,
      conditionID: harvest.conditionID ?? conditions[0]?.id,
    },
    enableReinitialize: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    // Check if Trap Net and if we're defaulting to blank
    if (useRadioControl && formik.values.gridKey.length) {
      let gk;

      Array.isArray(formik.values.gridKey)
        ? (gk = formik.values.gridKey[0])
        : (gk = formik.values.gridKey);

      const data: any = {
        grids: [
          {
            gridKey: gk,
            gridNumber: parseInt(gk.split("_")[1]),
            lakeName: getLakeFromGrid(gk),
          },
        ],
        ...formik.values,
      };
      updateHarvest(harvest.id, data);
    } else {
      const data: Catch = {
        grids: formik.values.gridKey.map((gk: any) => ({
          gridKey: gk,
          gridNumber: parseInt(gk.split("_")[1]),
          lakeName: getLakeFromGrid(gk),
        })),
        ...formik.values,
      };
      updateHarvest(harvest.id, data);
    }
  }, [formik.values]);

  return (
    <Form
      noValidate
      className={index > 0 ? "border-top pt-4 mt-4 border-primary" : ""}
      autoComplete="off"
    >
      {grids.length > 1 && !isSplitEvenly && (
        <div className="mb-3" role="group">
          <Form.Label className="mb-1">
            Grids where species was harvested:
          </Form.Label>{" "}
          <br />
          {grids.map((grid: Grid, i: number) => (
            <Form.Check
              inline
              className="me-4"
              name="gridKey"
              label={grid.gridNumber}
              value={grid.gridKey}
              type={useRadioControl ? "radio" : "checkbox"}
              key={`grid-${grid.gridKey}-${i}`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.gridKey.includes(grid.gridKey)}
            />
          ))}
        </div>
      )}
      <Row>
        <Col md={6}>
          <Form.Group className="mb-4">
            <Form.Label>Weight Landed (DO NOT INCLUDE DISCARD)</Form.Label>
            <InputGroup className="mb-4">
              <Form.Control
                ref={inputRef}
                type="number"
                name="landedWeight"
                value={formik.values.landedWeight}
                onChange={(e) => {
                  // Set min
                  if (parseInt(e.target.value) < 0) {
                    e.target.value = "0";
                  }

                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                aria-label="species total weight"
                aria-describedby="species-total-weight"
              />
              <InputGroup.Text id={`${harvest.id}-species-total-weight`}>
                Pounds
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-4">
            <Form.Label className="mb-3">Catch Condition</Form.Label>
            <br />

            {conditions.map((condition: Condition, i: number) => (
              <Form.Check
                key={`${harvest.id}-${i}`}
                inline
                label={condition.name}
                name="conditionID"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={condition.id}
                type="radio"
                id={`${harvest.id}-condition-${condition.id}`}
                checked={formik.values.conditionID == condition.id}
              />
            ))}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-4">
            <Form.Label>Discard Weight (estimated)</Form.Label>
            <InputGroup className="mb-4">
              <Form.Control
                type="number"
                name="discardWeight"
                value={formik.values.discardWeight}
                onChange={(e) => {
                  // Set min
                  if (parseInt(e.target.value) < 0) e.target.value = "0";
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                aria-label="discard Weight"
                aria-describedby="species-discard-weight"
              />
              <InputGroup.Text id={`${harvest.id}-species-discarded-weight`}>
                Pounds
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <div className="text-end">
        <span
          onClick={() => removeHarvest(harvest.id)}
          className="cursor-pointer text-danger"
        >
          Remove this harvest
        </span>
      </div>
    </Form>
  );
}
