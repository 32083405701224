import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { getFisher } from "../../api/getFisher";
import { User } from "../../models/user";
import { Vessel } from "../../models/vessel";
import { getUserVessels } from "../../api/getUserVessels";
import { lib } from "crypto-js";
import TripsTable from "../../components/tables/tripsTable";
import { getUserTrips } from "../../api/getUserTrips";
import Accordion from "react-bootstrap/esm/Accordion";
import { CatchReportPeriod } from "../../models/catchReportPeriod";
import { getFormattedDateMMDDYYYY } from "../../helpers/getFormattedDateMMDDYYYY";
import CatchReportsTable from "../../components/tables/catchReportsTable";
import { listCatchReportsAdmin } from "../../api/listCatchReportsAdmin";
import Button from "react-bootstrap/esm/Button";
import DailyTripModal from "../../components/dailyTrips/dailyTripModal";
import { Gear } from "../../models/gear";
import { MeshSize } from "../../models/meshSize";
import { Condition } from "../../models/condition";
import { Species } from "../../models/species";
import { lookupTripTypes } from "../../api/lookupTripTypes";
import { lookupGear } from "../../api/lookupGear";
import { lookupLakes } from "../../api/lookupLakes";
import { lookupSpecies } from "../../api/lookupSpecies";
import { lookupMeshSizes } from "../../api/lookupMeshSizes";
import { lookupConditions } from "../../api/lookupConditions";
import DropdownButton from "react-bootstrap/esm/DropdownButton";
import Spinner from "react-bootstrap/esm/Spinner";
import ReadonlyReviewModal from "../../components/dailyTrips/readonlyReviewModal";
import SubmitEditedCatchReportModal from "../../components/submitCatchReport/submitEditedCatchReportModal";
import SubmitCatchReportModal from "../../components/submitCatchReport/submitCatchReportModal";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import Dropdown from "react-bootstrap/esm/Dropdown";
import { getCatchReportPeriods } from "../../api/getCatchReportPeriods";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";
import { putLicenseActivate } from "../../api/putLicenseActivate";
import { putLicenseDeactivate } from "../../api/putLicenseDeactivate";

export default function FisherOverview() {
  let { userID }: any = useParams();
  if (userID) userID = parseInt(userID);

  const [fisher, setFisher] = useState<User>();
  const [vessels, setVessels] = useState<Vessel[]>([]);
  const [dailyTrips, setDailyTrips] = useState([]);
  const [submittedCatchReports, setSubmittedCatchReports] = useState([]);
  const [loadingDailyTrips, setLoadingDailyTrips] = useState(true);
  const [loadingCatchReports, setLoadingCatchReports] = useState(true);
  const [refreshingDailyTrips, setRefreshingDailyTrips] = useState(false);
  const [activeAccordionKey, setActiveAccordionKey] = useState<string[]>([]);
  const [loadMoreCatchReportsLimit, setLoadMoreCatchReportsLimit] =
    useState<number>(6);
  const [catchReportPeriods, setCatchReportPeriods] = useState<
    CatchReportPeriod[]
  >([]);

  //Lookups
  const [tripTypes, setTripTypes] = useState([]);
  const [gearList, setGearList] = useState<Gear[]>([]);
  const [meshList, setMeshList] = useState<MeshSize[]>([]);
  const [speciesList, setSpeciesList] = useState<Species[]>([]);
  const [conditionsList, setConditionsList] = useState<Condition[]>([]);
  const [lakesList, setLakesList] = useState<any>([]);

  // Child component references
  const dailyTripsModalRef = useRef<HTMLFormElement>(null);
  const readonlyReviewModalRef = useRef<HTMLFormElement>(null);
  const submitCatchReportModalRef = useRef<HTMLFormElement>(null);

  // On load get fisher info
  useEffect(() => {
    // Don't fetch initial values if there is no user id
    if (userID) {
      getFisher(userID).then((res) => {
        if (res) setFisher(res);
      });

      getUserVessels(userID).then((res) => {
        if (res) setVessels(res.data);
      });

      getUserTrips(userID).then((res) => {
        if (res) {
          setLoadingDailyTrips(false);
          setDailyTrips(res);
        }
      });

      getCatchReportPeriods({ userID: userID }).then((res) => {
        if (res) setCatchReportPeriods(res);
      });

      listCatchReportsAdmin({
        userID: userID,
      }).then((res) => {
        if (res) {
          setLoadingCatchReports(false);
          setSubmittedCatchReports(res);
        }
      });

      lookupTripTypes().then((res) => {
        if (res) setTripTypes(res);
      });

      lookupGear().then((res) => {
        if (res) setGearList(res);
      });

      lookupMeshSizes().then((res) => {
        if (res) setMeshList(res);
      });

      lookupSpecies().then((res) => {
        if (res) setSpeciesList(res);
      });

      lookupConditions().then((res) => {
        if (res) setConditionsList(res);
      });

      lookupLakes().then((res) => {
        if (res) setLakesList(res);
      });
    }
  }, [userID]);

  const handleActivate = async () => {
    if (window.confirm("Are you sure you want to renew this license?")) {
      // put api call
      await putLicenseActivate(userID);
      // refresh fisher info
      getFisher(userID).then((res) => {
        if (res) setFisher(res);
      });
    }
  };

  const handleDeactivate = async () => {
    if (window.confirm("Are you sure you want to expire this license?")) {
      // put api call
      await putLicenseDeactivate(userID);
      // refresh fisher info
      getFisher(userID).then((res) => {
        if (res) setFisher(res);
      });
    }
  };

  const loadMoreCatchReports = () => {
    setLoadMoreCatchReportsLimit(loadMoreCatchReportsLimit + 6);
  };

  const handleRefreshDailyTrips = async () => {
    setRefreshingDailyTrips(true);

    getUserTrips(userID).then((res) => {
      if (res) {
        setRefreshingDailyTrips(false);
        setDailyTrips(res);
      }
    });

    getCatchReportPeriods({ userID: userID }).then((res) => {
      if (res) setCatchReportPeriods(res);
    });
  };

  // Toggle Catch Report Accordion selection
  const handleAccordionSelect = (eventKey: any) => {
    setActiveAccordionKey(eventKey);
  };

  return (
    <div className="py-5">
      <Container>
        {/* Modals Start */}
        <DailyTripModal
          refId={dailyTripsModalRef}
          handleRefreshDailyTrips={handleRefreshDailyTrips}
          userID={userID}
          fisherID={userID}
          gearList={gearList}
          meshList={meshList}
          speciesList={speciesList}
          conditionsList={conditionsList}
          lakesList={lakesList}
        />

        <SubmitCatchReportModal
          userID={userID}
          isAdmin={true}
          refId={submitCatchReportModalRef}
          handleRefreshDailyTrips={handleRefreshDailyTrips}
        />

        <ReadonlyReviewModal refId={readonlyReviewModalRef} />
        {/* Modals end */}

        <h1 className="text-primary fw-bold mb-5">
          {fisher ? fisher?.displayName : "Loading user..."}
        </h1>

        {/* General Info */}
        <h3 className="fw-normal">General Info</h3>
        <div className="form-card">
          <Row className="mb-3">
            <Col lg={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <span className="fw-bold">Status: </span>
                  {fisher?.status}
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="fw-bold">Username: </span>
                  {fisher?.userName}
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="fw-bold">Tribe: </span>
                  {fisher?.profile?.organizationName}
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="fw-bold">Commercial License: </span>
                  {fisher?.commercialLicense}{" "}
                  {fisher?.licenseStatus?.toLowerCase().includes("commercial") ? (
                    ""
                  ) : (
                    <Badge className="ms-2" bg="warning">
                      (Deactivated)
                    </Badge>
                  )}
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="fw-bold">Vessels: </span>
                  <br />
                  {vessels.length > 0 && (
                    <ul>
                      {vessels.map((vessel, i) => (
                        <li key={i}>
                          {vessel.name} - {vessel.registrationNumber}
                        </li>
                      ))}
                    </ul>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col lg={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <span className="fw-bold">Name: </span>
                  {fisher?.firstName} {fisher?.lastName}
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="fw-bold">Email: </span>
                  {fisher?.email}
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="fw-bold">Phone: </span>
                  {fisher?.phone}
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="fw-bold">Address: </span>
                  {fisher?.profile?.address1} {fisher?.profile?.address2}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Link to={`/user/edit/${userID}`}>
            <Button variant="outline-primary me-2">Edit User</Button>
          </Link>

          {/* Active or deactivate current license  */}
          {fisher?.licenseStatus?.toLowerCase().includes("commercial") ? (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="activate-tooltip">
                  Sets the currently active license end date to today.
                </Tooltip>
              }
            >
              <Button variant="outline-danger" onClick={handleDeactivate}>
                Deactivate Commercial License
              </Button>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="activate-tooltip">
                  Creates a new commercial license with the same license number
                  as the previous (must have one already). If no commercial
                  licenses are active, the license is set from today’s date to
                  12/31/current year.
                </Tooltip>
              }
            >
              <Button variant="outline-primary" onClick={handleActivate}>
                Activate Commercial License
              </Button>
            </OverlayTrigger>
          )}
        </div>

        {/* daily trips */}
        <h3 className="fw-normal">
          Daily Trips{refreshingDailyTrips && " - Refreshing..."}
        </h3>
        <div className="form-card">
          <TripsTable
            dailyTripRef={dailyTripsModalRef}
            dailyTrips={dailyTrips}
            loadingDailyTrips={loadingDailyTrips}
          />

          <div className="mt-2 d-flex flex-wrap">
            <Button
              variant="outline-primary me-2"
              className="d-inline mb-2 me-2"
              onClick={() =>
                dailyTripsModalRef.current?.newDailyTrip(tripTypes[0])
              }
            >
              New Daily Trip
            </Button>

            {catchReportPeriods.length > 0 && (
              <div className="d-inline mb-2 me-2">
                <DropdownButton
                  as={ButtonGroup}
                  title="Submit Catch Report"
                  variant="outline-success"
                  className=""
                >
                  {catchReportPeriods.map((period: CatchReportPeriod, i) => (
                    <Dropdown.Item
                      eventKey={i}
                      key={i}
                      onClick={() =>
                        submitCatchReportModalRef.current?.handleShow(period)
                      }
                    >
                      {getFormattedDateMMDDYYYY(period.beginDate)} -{" "}
                      {getFormattedDateMMDDYYYY(period.endDate)}
                      {period.late && (
                        <strong className="ms-3 text-danger">Late</strong>
                      )}
                      {period.tripCount === 0 && (
                        <strong className="ms-3 text-primary">
                          No Fishing
                        </strong>
                      )}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
            )}
          </div>
        </div>

        {/* Catch Reports */}
        <h3 className="fw-normal">Catch Reports</h3>
        {loadingCatchReports && (
          <div className="">
            <Spinner animation="border" variant="primary" size="sm" />
            <span className="h6 ms-2 mb-0 text-primary">
              Loading Catch Reports...
            </span>
          </div>
        )}
        {submittedCatchReports.length > 0 && (
          <>
            <Accordion
              alwaysOpen
              className="mb-4 submitted-catch-reports-accordion"
              activeKey={activeAccordionKey}
              onSelect={handleAccordionSelect}
            >
              {submittedCatchReports
                .slice(0, loadMoreCatchReportsLimit)
                .map((period: CatchReportPeriod, i) => (
                  <Accordion.Item
                    key={i}
                    eventKey={`${i}`}
                    className="mb-2 shadow-sm"
                  >
                    <Accordion.Header>
                      {getFormattedDateMMDDYYYY(period.beginDate)} -{" "}
                      {getFormattedDateMMDDYYYY(period.endDate)}
                    </Accordion.Header>
                    <Accordion.Body>
                      <CatchReportsTable
                        period={period}
                        active={activeAccordionKey.includes(i.toString())}
                        isAdmin={true}
                        userID={userID}
                        reviewDailyTripRef={readonlyReviewModalRef}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
            {loadMoreCatchReportsLimit < submittedCatchReports.length && (
              <div className="text-center">
                <Button
                  variant="outline-primary"
                  onClick={loadMoreCatchReports}
                >
                  Load More Catch Reports
                </Button>
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  );
}
