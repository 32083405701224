import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import EditLogo from "../../components/formControls/editLogo";
import EditOrgEmail from "../../components/formControls/editOrgEmail";
import {
  getOrganizationID,
  getOrganizationName,
} from "../../helpers/authHelper";
import { useEffect, useState } from "react";
import { Organization } from "../../models/organization";
import EditCatchReportDeadlines from "../../components/formControls/editCatchReportDeadlines";
import { getSettings } from "../../api/getSettings";

export default function AdminSettings() {
  const [organizationSettings, setOrganizationSettings] =
    useState<Organization>({
      id: getOrganizationID(),
      code: "",
      name: "",
      title: "",
      subtitle: "",
      supportEmail: "",
      logo: "",
      address1: "",
      address2: "",
      city: "",
      zip: "",
      stateName: "",
      stateCode: "",
      phone: "",
      email: "",
      hasGracePeriod: false,
      gracePeriodDays: 0,
      deadlines: [],
    });

  useEffect(() => {
    const fetchOrgSettings = async () => {
      const response = await getSettings();
      if (response) setOrganizationSettings(response);
    };
    fetchOrgSettings();
  }, []);

  return (
    <div className="py-5">
      <Container>
        <h1 className="text-primary mb-5">Settings</h1>
        {/* Organization */}
        <Row>
          <Col lg={3}>
            <h2 className="form-header">Organization</h2>
          </Col>
          <Col>
            <div className="form-card">
              <p className="h4 fw-normal mb-0">{getOrganizationName()}</p>
            </div>
          </Col>
        </Row>

        {/* Contact */}
        <Row>
          <Col lg={3}>
            <h2 className="form-header">Contact</h2>
          </Col>
          <Col>
            <EditOrgEmail
              organizationID={organizationSettings.id}
              value={organizationSettings}
            />
          </Col>
        </Row>

        {/* Logo */}
        <Row>
          <Col lg={3}>
            <h2 className="form-header">Logo</h2>
          </Col>
          <Col>
            <EditLogo
              organizationID={organizationSettings.id}
              base64Image={organizationSettings.logo}
            />
          </Col>
        </Row>

        {/* Catch Report Deadlines */}
        <Row>
          <Col lg={3}>
            <h2 className="form-header">
              {organizationSettings.id === 1
                ? "Export Dates"
                : "Reporting Period End Dates"}
            </h2>
          </Col>
          <Col>
            <EditCatchReportDeadlines
              organizationID={organizationSettings.id}
              deadlines={organizationSettings.deadlines}
              hasGracePeriod={organizationSettings.hasGracePeriod}
              gracePeriodDays={organizationSettings.gracePeriodDays}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
